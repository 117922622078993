<template>
  <div>
    <base-module :moduleOptions="options" title="Abogados" ref="module">
      <template v-slot:documentsformfields>
        <b-form-group label="Nombre" class="flex-1">
          <b-form-input name="name"> </b-form-input>
        </b-form-group>
        <b-form-group label="Archivo" class="flex-1">
          <b-form-file
            name="file"
            ref="file"
            accept=".jpg, .png, .gif, .pdf"
          ></b-form-file>
        </b-form-group>
      </template>
      <template v-slot:recorddetails="slotProps">
        <div class="flex flex-col space-y-2">
          <h3>{{ slotProps.full_name }}</h3>
          <div>
            <strong>C.C.</strong>
            {{ slotProps.identity_number | numericFormat }}
          </div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <span class="font-semibold">email:</span> {{ slotProps.email }}
            </div>
            <div class="flex-1">
              <span class="font-semibold">Teléfono:</span> {{ slotProps.phone }}
            </div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <span class="font-semibold">Tarjeta profesional:</span>
              {{ slotProps.professional_card }}
            </div>
            <div class="flex-1" v-if="slotProps.specialty">
              <span class="font-semibold">Especialidad:</span>
              {{ slotProps.specialty }}
            </div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <span class="font-semibold">Dirección:</span>
              {{ slotProps.address }}
            </div>
            <div v-if="slotProps.city !== undefined" class="flex-1">
              <span class="font-semibold">Ciudad:</span>
              {{ slotProps.city.name }}
            </div>
          </div>
        </div>
      </template>
    </base-module>
    <lawyer-form :handlePostSubmit="handlePostSubmit" :record="selectedRow" />
  </div>
</template>

<script>
import LawyerForm from "./LawyerForm";
import BaseModule from "@/components/Base/BaseModule";
import { customComponentActions } from "@/mixins/actions.js";

export default {
  inject: ["lawyersRepository", "citiesRepository"],

  mixins: [customComponentActions],

  components: {
    BaseModule,
    LawyerForm
  },

  data() {
    return {
      options: {
        columns: [
          {
            header: "Cédula",
            prop: "identity_number"
          },
          {
            header: "Nombre Completo",
            prop: "full_name"
          },
          {
            header: "Dirección",
            prop: "address"
          },
          {
            header: "Teléfono",
            prop: "phone"
          },
          {
            header: "e-mail",
            prop: "email"
          },
          {
            header: "Ciudad",
            prop: "city.name"
          }
        ],
        repository: {
          name: "lawyersRepository"
        },
        sortable: ["identity_number", "full_name"],
        primary: "id"
      },

      selectedRow: null
    };
  },

  methods: {
    handlePostSubmit() {
      this.$refs.module.handleLoadData();
    }
  }
};
</script>

<style lang="scss" scoped></style>
