import { render, staticRenderFns } from "./LawyerForm.vue?vue&type=template&id=7bac0a36&scoped=true&"
import script from "./LawyerForm.vue?vue&type=script&lang=js&"
export * from "./LawyerForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bac0a36",
  null
  
)

export default component.exports